import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';
import TextHeadingWrapper from '../../components/layout/TextHeadingWrapper';

import { ViewportBreakpoints } from '../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import Accordion from '@rotaready/frecl/build/Accordion';
import Text from '@rotaready/frecl/build/Text';
import H2 from '@rotaready/frecl/build/H2';
import Button from '@rotaready/frecl/build/Button';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const GreyWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grey10};
`;

const FaqContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 60px 160px;
  }
`;

const ButtonBox = styled.div`
  text-align: center;

  button {
    margin: 10px;
  }
`;

const faqData = [
  { question: 'What if I have multiple sites/venues?', answer: 'No problem! Rotaready is designed for multi-site operators. It can be deployed in as many locations or sites as you require. Settings can be tailored uniquely for each site too, which is handy if things vary across different parts of your business.' },
  { question: 'What if I have sites located internationally?', answer: 'Rotaready works great across different timezones and currencies. We have customers in over 20 countries across the globe (as far and wide as Brazil and South Korea).' },
  { question: 'Do you have an SLA?', answer: 'Yes. Businesses rely on Rotaready to underpin their operations. We have SLAs for enterprise customers. Feel free to contact us for more information.' },
  { question: 'Do you offer training?', answer: 'Yes. But we\'re confident you\'ll find Rotaready a doddle to use! Email and telephone support is provided as standard. We also have a brilliant Help Centre available online. For enterprise customers, we can create a training package around your needs. This can include in-person training sessions for your managers, plus sets of printed learning materials if required.' },
  { question: 'Is my data secure?', answer: 'We take data security and privacy extremely seriously. Rotaready is fully compliant with UK Data Protection laws and the GDPR (General Data Protection Regulation). You can learn more about our commitment to security and privacy on our Policies page.' },
  { question: 'How do your 3rd party integrations work?', answer: 'The majority of our integrations are fully real-time, which means Rotaready and the 3rd party system are kept continually in-sync. These leverage Rotaready\'s API and event-driven Webhooks system. Sadly, some 3rd party systems aren\'t capable of being updated in real-time; these are instead updated on a periodic basis using secure file transfers, such as nightly or at regular intervals throughout the day.' },
  { question: 'Do you offer support?', answer: 'Absolutely, we\'re on hand Monday to Friday, from 9am to 5pm (GMT). In fact, our average email response time is a only a few minutes, with almost all queries resolved in under an hour.' },
  { question: 'Is it difficult and time consuming to get started?', answer: 'Not at all, in fact we do the heavy lifting for you. When you\'re ready to get started we\'ll do the configuration on your behalf, import your initial data and deliver any required training.' },
  { question: 'Do I get an account manager?', answer: 'Yes. You\'ll be assigned a dedicated Customer Success Manager when you join us. Their job is to make sure you get the most out of Rotaready.' },
];

const header = {
  backgroundColor: 'grey10',
  invertColors: false,
};

class FaqPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAccordions: new Set(),
    };
  }

  openIntercom = () => {
    Intercom('show');
  };

  onAccordionClick = (index) => {
    const {
      openAccordions,
    } = this.state;

    if (openAccordions.has(index)) {
      openAccordions.delete(index);
    } else {
      openAccordions.add(index);
    }

    this.setState(() => ({ openAccordions }));
  };

  render() {
    const {
      openAccordions,
    } = this.state;

    return (
      <Body header={header}>
        <SEO
          title="FAQ - Rotaready"
          description="Frequently asked questions from hospitality operators and retailers about our staff scheduling software."
          url="faq"
        />

        <GreyWrapper>
          <ResponsiveContainer>
            <ContentWrapper>
              <TitleWrapper>
                <H1 uistyle="brand160" text="FAQ" />
              </TitleWrapper>

              <FaqContainer>
                {faqData.map((item, index) => (
                  <Accordion
                    title={item.question}
                    onAccordionClick={() => this.onAccordionClick(index)}
                    show={openAccordions.has(index)}
                    key={index}
                    fullWidth
                  >
                    <Text text={item.answer} />
                  </Accordion>
                ))}
              </FaqContainer>
            </ContentWrapper>
          </ResponsiveContainer>
        </GreyWrapper>

        <ResponsiveContainer>
          <ContentWrapper>
            <TextHeadingWrapper>
              <H2 text="Looking for more answers?" uistyle="brand160" />
            </TextHeadingWrapper>

            <TextHeadingWrapper>
              <Text text="If the answer you're looking for isn't here, our friendly team are on hand by live chat, email or phone." size="xl" />
            </TextHeadingWrapper>

            <ButtonBox>
              <Button uistyle="primary" text="Live chat" size="lg" onClick={this.openIntercom} />

              <Link to="/contact">
                <Button uistyle="primary" text="Email/call" size="lg" ghost borderless />
              </Link>
            </ButtonBox>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default FaqPage;
